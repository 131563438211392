import store from "../redux/store";
import { SERVICE_API_BASES } from "../services/constants";

const development =
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENVIRONMENT === "development";

export const isDev = () => development;

export const getBaseUrl = (base: keyof typeof SERVICE_API_BASES) => {
  if (development) {
    const apiVersion = store.getState().settings.apiVersions;
    const regex = /[^-]+$/; // Get the substring after the last dash
    const newBase =
      SERVICE_API_BASES[base]?.replace(regex, `${apiVersion[base]}`) ||
      SERVICE_API_BASES[base];

    return newBase;
  }
  return SERVICE_API_BASES[base];
};
